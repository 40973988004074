import { Box, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CategoryWithImage } from "types";
import CategoriesBox from "./CategoriesBox";
import { getCategories } from "../../utils/requests";

const OfferPage = () => {
  const [categories, setCategories] = useState<CategoryWithImage[]>([]);

  const fetchCategories = async () => {
    const fetchedCategories = await getCategories();
    setCategories(fetchedCategories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Box>
      <Heading as="h1" my={4} size="xl" textAlign="center" color="gray.700">
        Oferta
      </Heading>
      <CategoriesBox categories={categories} />
    </Box>
  );
};

export default OfferPage;
