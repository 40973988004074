import { Box, Button, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { tealColor } from "../../../theme";
import CategoriesTable from "./CategoriesTable";

const AdminCategoriesPage = () => {
  const navigate = useNavigate();

  const handleNavigateToAddNewCategoryPage = () => {
    navigate("/admin/kategorie/nowa");
  };

  return (
    <Box flexBasis="80%">
      <Heading p={4} as="h1" color={tealColor} textAlign="center">
        Kategorie
      </Heading>
      <Button colorScheme="blue" onClick={handleNavigateToAddNewCategoryPage}>
        Nowa kategoria
      </Button>
      <CategoriesTable />
    </Box>
  );
};

export default AdminCategoriesPage;
