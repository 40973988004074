import { CategoryWithImage, Image, ProductWithImagesAndCategory } from "types";
import { config } from "../config";

export const getProductImageUrl = (product: ProductWithImagesAndCategory): string => {
  return product.images[0]
  ? getProductMainImageUrl(product)
  : "/brak-obrazka.png";
}

const getProductMainImageUrl = (product: ProductWithImagesAndCategory): string => {
  return getImageUrl(product.images[0]);
};

export const getCategoryImageUrl = (category: CategoryWithImage): string => {
  return category.image ? getImageUrl(category.image) : "/brak-obrazka.png";
}

export const getImageUrl = (image: Image): string => {
  const { name, extension } = image;
  return name ? `${config.api}/${name}${extension}` : "/brak-obrazka.png";
};
