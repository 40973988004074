import { Box, Button, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { tealColor } from "../../../theme";
import ProductsTable from "./ProductsTable";

const AdminProductsPage = () => {
  const navigate = useNavigate();

  const handleNavigateToAddNewProductPage = () => {
    navigate("/admin/produkty/nowy");
  };

  return (
    <Box flexBasis="80%">
      <Heading p={4} as="h1" color={tealColor} textAlign="center">
        Produkty
      </Heading>
      <Button colorScheme="blue" onClick={handleNavigateToAddNewProductPage}>
        Nowy produkt
      </Button>
      <ProductsTable />
    </Box>
  );
};

export default AdminProductsPage;
