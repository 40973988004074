import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProductImageUrl } from "../../../utils/getImageUrl";
import { ProductWithImagesAndCategory } from "types";
import {
  deleteProduct,
  getProducts,
} from "../../../utils/requests";

const ProductsTable = () => {
  const [products, setProducts] = useState<ProductWithImagesAndCategory[]>([]);
  const navigate = useNavigate();
  const toast = useToast();

  const fetchProducts = async () => {
    const fetchedProducts = await getProducts();
    setProducts(fetchedProducts);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleNavigateToEditProductPage = (id: string) => {
    navigate(`/admin/produkty/${id}`);
  };

  const handleDeleteProduct = async (id: string) => {
    const status = await deleteProduct(id);

    if(status !== 200) {
      return toast({
        title: 'Nie udało sie usunąć produktu.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }

    toast({
      title: 'Produkt usunięty pomyślnie.',
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    fetchProducts();
  }


  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Produkt</Th>
            <Th>Krótki opis</Th>
            <Th>Cena</Th>
            <Th>Kategoria</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {products.map((product) => {
            return (
              <Tr key={product.id}>
                <Td textAlign="center"><Text mb={2} fontWeight="bold">{product.name}</Text><Image mx="auto" src={getProductImageUrl(product)} width="100px" boxShadow="0 2px 5px rgba(0,0,0,0.2)"/></Td>
                <Td>{product.shortDescription}</Td>
                <Td>{product.price}zł</Td>
                <Td>{ product.category ? product.category.name : "Brak"}</Td>
                <Td></Td>
                <Td>
                  <Button
                    colorScheme="teal"
                    onClick={() => handleNavigateToEditProductPage(product.id)}
                  >
                    Edytuj
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => handleDeleteProduct(product.id)}
                  >
                    Usuń
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;
