import {
  Box,
  Flex,
} from "@chakra-ui/react";
import AboutBox from "./AboutBox";
import ContactTypes from "./ContactTypes";

const Footer = () => {

  return (
    <Box as="footer">
      <Flex
        flexDirection={["column", "row"]}
        p={8}
        bgColor="gray.700"
        justifyContent="center"
        alignItems={["center", "flex-start"]}
      >
        <ContactTypes />
        <AboutBox />
      </Flex>
    </Box>
  );
};

export default Footer;
