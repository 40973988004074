import { EmailIcon, Search2Icon, TimeIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import InfoCard from "./InfoCard";

const InfoCards = () => {
  return (
    <Box as="section" pt={12} backgroundColor="gray.700">
      <Heading as="h2" size="xl" mb="4" textAlign="center" color="white">
        Jak z nami współpracować?
      </Heading>
      <Flex
        flexDirection={["column", "column", "row"]}
        justifyContent="center"
        alignItems="center"
        pt={6}
        gap={6}
      >
        <InfoCard
          IconComponent={Search2Icon}
          title="Wybierz produkt"
          text="Znajdź produkty, których szukasz"
        />
        <InfoCard
          IconComponent={EmailIcon}
          title="Skontaktuj się z nami"
          text="Napisz do nas maila lub zadzwoń, aby dowiedzieć się szczegółów i dokonać zamówienia"
        />
        <InfoCard
          IconComponent={TimeIcon}
          title="Realizacja 2-4 dni"
          text="Czas realizacji to zwykle od 2 do 4 dni roboczych"
        />
      </Flex>
      <Image src="fala.png" width="100%" height="40vh"/>
    </Box>
  );
};

export default InfoCards;
