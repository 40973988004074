import { Box, Button, Flex, Heading, Image, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ProductWithImagesAndCategory } from "types";
import { useContact } from "../../context/ContactContext";
import { tealColor } from "../../theme";
import { getProductImageUrl } from "../../utils/getImageUrl";

interface Props {
  product: ProductWithImagesAndCategory;
}

const ProductCard = ({ product }: Props) => {
  const { setMessage } = useContact();
  const navigate = useNavigate();

  const handleAskForProduct = () => {
    const questionString = `Dzień dobry! Mam pytanie o produkt ${product.name}.\n`;
    setMessage((prev) => {
      return prev === "" ? `${questionString}` : `${prev}\n${questionString}`;
    });
    navigate("/kontakt");
  };

  return (
    <Flex
      flexDirection="column"
      flexBasis={["90%", "45%", "45%", "30%", "20%"]}
      boxShadow="0 2px 6px rgba(0,0,0,0.2)"
      borderRadius={6}
      justifyContent="space-between"
    >
      <Box position="relative" mb={5}>
        <Image src={getProductImageUrl(product)} width="100%" height="150px" objectFit="cover" />
        <Box
          position="absolute"
          py="0"
          px="8px"
          bottom="-18px"
          left="20px"
          height="36px"
          color="white"
          lineHeight="36px"
          backgroundColor={tealColor}
          fontWeight="bold"
        >
          {product.price}zł
        </Box>
      </Box>
      <Box padding={2}>
        <Heading as="h3" size="sm" my={2}>
          {product.name}
        </Heading>
        <Text>{product.shortDescription}</Text>
      </Box>
      <Button
        mt={2}
        colorScheme="teal"
        borderRadius={0}
        onClick={handleAskForProduct}
      >
        Zapytaj o produkt
      </Button>
      {product.allegroURL && <LinkBox><LinkOverlay href={product.allegroURL} target="_blank"><Button borderRadius={0} colorScheme="orange" width="100%">Zobacz na Allegro</Button></LinkOverlay></LinkBox>}
      {product.olxURL && <LinkBox><LinkOverlay href={product.olxURL} target="_blank"><Button borderRadius={0} colorScheme="cyan" width="100%">Zobacz na OLX</Button></LinkOverlay></LinkBox>}
      {product.sprzedajemyURL && <LinkBox><LinkOverlay href={product.sprzedajemyURL} target="_blank"><Button borderRadius={0} colorScheme="red" width="100%">Zobacz na Sprzedajemy.pl</Button></LinkOverlay></LinkBox>}
    </Flex>
  );
};

export default ProductCard;
