import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Image,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CategoryWithImage } from "types";
import { getCategoryImageUrl } from "../../../utils/getImageUrl";
import { deleteCategory, getCategories, getCategoryProducts } from "../../../utils/requests";

const CategoriesTable = () => {
  const [categories, setCategories] = useState<CategoryWithImage[]>([]);
  const navigate = useNavigate();
  const toast = useToast();

  const fetchCategories = async () => {
    const fetchedCategories = await getCategories();
    setCategories(fetchedCategories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleNavigateToEditCategoryPage = (id: string) => {
    navigate(`/admin/kategorie/${id}`);
  };

  const handleDeleteCategory = async (id: string) => {
    const {total} = await getCategoryProducts(id);
    if(total > 0) return alert("Nie możesz usunąć kategori. Są do niej dopisane produkty.");
     
    const status = await deleteCategory(id);
    
    if(status !== 200) {
      return toast({
        title: 'Nie udało się usunąć kategorii.',
        description: 'Prawdopodobnie do kategorii dowiązane są produkty.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }

    toast({
      title: 'Kategoria usunięta pomyślnie',
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
    fetchCategories();
  };

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nazwa kategorii</Th>
            <Th>Alias</Th>
            <Th>Akcja</Th>
          </Tr>
        </Thead>
        <Tbody>
          {categories.map((category) => {
            return (
              <Tr key={category.id}>
                <Td textAlign="center"><Text mb={2} fontWeight="bold">{category.name}</Text><Image mx="auto" src={getCategoryImageUrl(category)} width="100px" boxShadow="0 2px 5px rgba(0,0,0,0.2)"/></Td>
                <Td>{category.alias}</Td>
                <Td>
                  <Button
                    colorScheme="teal"
                    onClick={() =>
                      handleNavigateToEditCategoryPage(category.id)
                    }
                  >
                    Edytuj
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => handleDeleteCategory(category.id)}
                  >
                    Usuń
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default CategoriesTable;
