import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  searchPhrase: string;
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void;
}

const SearchBox = ({searchPhrase, setSearchPhrase, handleSearch}: Props) => {
  const handleSearchPhraseChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchPhrase(e.currentTarget.value);
  }

  return ( 
    <InputGroup size='md' width="400px" maxWidth="90%" mx="auto">
      <Input
        placeholder='Wpisz frazę, której szukasz...'
        value={searchPhrase}
        onChange={handleSearchPhraseChange}
      />
      <InputRightElement >
        <Button colorScheme="teal" onClick={handleSearch}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
 
export default SearchBox;