import { CategoryWithImage, Image, ProductWithImagesAndCategory, Mail } from "types";
import { config } from "../config";

export const getCategories = async (take = 99999): Promise<CategoryWithImage[]> => {
  const response = await fetch(`${config.api}/categories?take=${take}`);
  const data = await response.json();
  return data.categories;
};

export const getCategory = async (id: string) => {
  const response = await fetch(`${config.api}/categories/${id}`);
  const data = await response.json();
  return data;
};


export const getCategoryProducts = async (id: string): Promise<{products: ProductWithImagesAndCategory[], total: number}> => {
  const response = await fetch(`${config.api}/categories/${id}/products`);
  const data = await response.json();
  return data;
};

export const addCategory = async (categoryName: string, alias: string) => {
  const body = JSON.stringify({ name: categoryName, alias });
  const response = await fetch(`${config.api}/categories`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body,
  });
  const data = await response.json();
  return data;
};

export const updateCategory = async (
  id: string,
  categoryName: string,
  alias: string
) => {
  const body = JSON.stringify({ name: categoryName, alias });
  const response = await fetch(`${config.api}/categories/${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body,
  });
  const data = await response.json();
  return data;
};

export const deleteCategory = async (id: string): Promise<number> => {
  const response = await fetch(`${config.api}/categories/${id}`, {
    method: "DELETE",
    credentials: "include",
  });

  return response.status;
};

export const addImageToCategory = async (categoryId: string, imageName: string) => {
  const response = await fetch(`${config.api}/categories/${categoryId}/images/${imageName}`, {
    method: 'POST',
    credentials: 'include',
  });
}

export const removeImageFromCategory = async (categoryId: string, imageName: string): Promise<number> => {
  const response = await fetch(`${config.api}/categories/${categoryId}/images/${imageName}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  return response.status;
}

export const getProducts = async (keyword = '', take = 99999) => {
  const response = await fetch(`${config.api}/products?take=${take}&keyword=${keyword}`);
  const data = await response.json();
  return data.products;
};

export const getProduct = async (id: string):Promise<ProductWithImagesAndCategory> => {
  const response = await fetch(`${config.api}/products/${id}`);
  const data = await response.json();
  return data;
}

export const addProduct = async (name: string, shortDescription: string, price: string, allegroURL = "", olxURL = "", sprzedajemyURL = "") => {
  const response = await fetch(`${config.api}/products`, {
    method: 'POST',
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({name, shortDescription, price, allegroURL, olxURL, sprzedajemyURL}),
    credentials: "include",
  });
  const data = await response.json();
  return data;
};

export const updateProduct = async (id: string, name: string, shortDescription: string, price: string, allegroURL = "", olxURL = "", sprzedajemyURL = "") => {
  const body = JSON.stringify({ name, shortDescription, price, allegroURL, olxURL, sprzedajemyURL });
  const response = await fetch(`${config.api}/products/${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body,
  });
  const data = await response.json();
  return data;
}

export const deleteProduct = async (id: string): Promise<number> => {
  const response = await fetch(`${config.api}/products/${id}`, {
    method: "DELETE",
    credentials: "include",
  });

  return response.status;
}

export const addProductToCategory = async (categoryId: string, productId: string) => {
  const response = await fetch(`${config.api}/categories/${categoryId}/products/${productId}`, {
    method: 'POST',
    credentials: "include",
  })
  return response.status;
}

export const addImageToProduct = async (productId: string, imageName: string) => {
  const response = await fetch(`${config.api}/products/${productId}/images/${imageName}`, {
    method: 'POST',
    credentials: 'include',
  });
}

export const removeImageFromProduct = async (productId: string, imageName: string): Promise<number> => {
  const response = await fetch(`${config.api}/products/${productId}/images/${imageName}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  return response.status;
}

export const getImages = async (take = 99999) => {
  const response = await fetch(`${config.api}/images`);
  const data = await response.json();
  return data.images;
};

export const uploadImage = async (formData: FormData): Promise<Image> => {
  const response = await fetch(`${config.api}/images`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });
  const data = await response.json();
  return { name: data.name, extension: data.extension } as Image;
};

export const deleteImage = async(name: string): Promise<number> => {
  const response = await fetch(`${config.api}/images/${name}`, {
    method: 'DELETE',
    credentials: "include",
  });

  return response.status;
};

export const sendMail = async (mail: Mail) => {
  const body = JSON.stringify(mail);

  const response = await fetch(`${config.api}/mail`, {
    method: 'POST',
    headers: {"Content-Type": "application/json"},
    body,
  });
  return response.status;
}

export const login = async (email: string, password: string): Promise<boolean> => {
  const response = await fetch(`${config.api}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
    credentials: "include",
  });
  return response.ok;
}

export const logout = async () => {
  const response = await fetch(`${config.api}/auth/logout`, {
    credentials: "include",
  });

  return response.status;
}