import { Navigate, Route, Routes } from "react-router-dom";
import AdminCategoriesPage from "../pages/Admin/Category/AdminCategoriesPage";
import AdminPage from "../pages/Admin/AdminPage";
import AdminProductsPage from "../pages/Admin/Product/AdminProductsPage";
import NewCategoryPage from "../pages/Admin/Category/NewCategoryPage";
import CategoryProductsPage from "../pages/CategoryProducts/CategoryProductsPage";
import ContactPage from "../pages/Contact/ContactPage";
import HomePage from "../pages/Home/HomePage";
import LoginPage from "../pages/Login/LoginPage";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import OfferPage from "../pages/Offer/OfferPage";
import RequireAuth from "./RequireAuth";
import EditCategoryPage from "../pages/Admin/Category/EditCategoryPage";
import EditProductPage from "../pages/Admin/Product/EditProductPage";
import NewProductPage from "../pages/Admin/Product/NewProductPage";
import AdminImagesPage from "../pages/Admin/Image/AdminImagesPage";
import SearchProductPage from "../pages/SearchProduct/SearchProductPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicy/PrivacyPolicyPage";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/strona-glowna" />}></Route>
      <Route path="/strona-glowna" element={<HomePage />}></Route>
      <Route path="/oferta" element={<OfferPage />}></Route>
      <Route path="/kontakt" element={<ContactPage />}></Route>
      <Route path="/polityka-prywatnosci" element={<PrivacyPolicyPage />}></Route>
      <Route path="/login" element={<LoginPage />}></Route>
      <Route
        path="/admin"
        element={
          <RequireAuth>
            <AdminPage />
          </RequireAuth>
        }
      >
        <Route path="kategorie/nowa" element={<NewCategoryPage />} />
        <Route path="kategorie/:id" element={<EditCategoryPage />} />
        <Route path="kategorie" element={<AdminCategoriesPage />} />
        <Route path="produkty/nowy" element={<NewProductPage />} />
        <Route path="produkty/:id" element={<EditProductPage />} />
        <Route path="produkty" element={<AdminProductsPage />} />
        <Route path="obrazy" element={<AdminImagesPage />} />
      </Route>
      <Route path="produkty" element={<SearchProductPage />} />
      <Route path="/:categoryAlias" element={<CategoryProductsPage />}></Route>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/nie-znaleziono" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
