import { Box, Flex, Heading } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { tealColor } from "../../theme";
import AdminAsideNavigation from "./AdminAsideNavigation";

const AdminPage = () => {
  return (
    <Box>
      <Flex>
        <AdminAsideNavigation />
        <Outlet />
      </Flex>
    </Box>
  );
};

export default AdminPage;
