import { Box, Flex, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { tealColor } from "../../theme";

interface PropsInterface {
  IconComponent: any;
  title: string;
  text: string | JSX.Element;
}

const ContactRow = ({ IconComponent, title, text }: PropsInterface) => {
  return (
    <Flex alignItems="center" mb={6}>
      <Box
        bgColor="gray.600"
        width="52px"
        height="52px"
        borderRadius="50%"
        textAlign="center"
      >
        <Box
          lineHeight="52px"
          fontSize="24px"
          color={tealColor}>
          {IconComponent}
        </Box>
      </Box>
      <VStack ml={2} alignItems="flex-start" justifyContent="center">
        <Heading as="h5" m="unset" fontSize="16px" color={tealColor}>{title}</Heading>
        <Text mt={1} fontWeight="bold" color="white">
          {text}
        </Text>
      </VStack>
    </Flex>
  );
};

export default ContactRow;
