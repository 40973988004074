import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { tealColor } from "../../theme";
import { login } from "../../utils/requests";

const LoginPage = () => {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useUser();

  const [email, setEmail] = useState<string>("");
  const [isEmailError, setEmailError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [isPasswordError, setPasswordError] = useState<boolean>(false);

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setEmail(e.currentTarget.value);
    setEmailError(e.currentTarget.value === "");
  };

  const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setPassword(e.currentTarget.value);
    setPasswordError(e.currentTarget.value === "");
  };

  const handleLogin = async () => {
    if (isEmailError || isPasswordError) return;
    const loggedIn = await login(email, password);

    if (loggedIn) {
      setIsLoggedIn(true);
      navigate("/strona-glowna");
    } else alert("Błąd podczas logowania");
  };

  return (
    <Box>
      <Heading as="h1" my={4} color={tealColor} textAlign="center">
        Strona logowania
      </Heading>
      <Box
        margin="60px auto"
        padding="40px"
        width="400px"
        maxWidth="90%"
        boxShadow="0 2px 5px rgba(0,0,0,0.2)"
        borderRadius={6}
      >
        <FormControl isInvalid={isEmailError} mb={6}>
          <FormLabel>Nazwa użytkownika</FormLabel>
          <Input
            type="text"
            name="email"
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailChange}
          />
          {isEmailError && (
            <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={isPasswordError} mb={6}>
          <FormLabel>Hasło</FormLabel>
          <Input
            type="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            onBlur={handlePasswordChange}
          />
          {isPasswordError && (
            <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
          )}
        </FormControl>
        <Box textAlign="right">
          <Button ml="auto" mr="0" colorScheme="teal" onClick={handleLogin}>
            Zaloguj się
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
