import { Divider, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { tealColor } from "../../theme";

interface PropsInterface {
  IconComponent: any;
  text: string;
  title: string;
}

const InfoCard = ({ IconComponent, text, title }: PropsInterface) => {
  return (
    <Flex
      flexDirection="column"
      width={["90%", "90%", "240px"]}
      alignItems="center"
      boxShadow={`xl`}
      p={6}
      borderTop="8px solid"
      borderColor={tealColor}
      color="white"
      borderRadius={4}
    >
      <Icon
        as={IconComponent}
        width={[12, 14, 16]}
        height={[12, 14, 16]}
        color={tealColor}
      />
      <Heading as="h4" fontSize="1.2em" my={[2, 2, 4]} textAlign="center">
        {title}
      </Heading>
      <Divider m={[2, 2, 4]} width="80%" borderColor="teal.400" />
      <Text textAlign="center" mt={[6, 6, 12]} mb={[2, 2, 4]}>
        {text}
      </Text>
    </Flex>
  );
};

export default InfoCard;
