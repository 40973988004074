import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link,
  useDisclosure,
  Image as UIImage,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { tealColor } from "../../../theme";
import { normalizeAlias } from "../../../utils/normalizeAlias";
import { addImageToCategory, getCategory, removeImageFromCategory, updateCategory } from "../../../utils/requests";
import {Image} from "types";
import ChooseImageModal from "../Product/ChooseImageModal";
import { getImageUrl } from "../../../utils/getImageUrl";

const EditCategoryPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();

  const [categoryName, setCategoryName] = useState<string>("");
  const [isCategoryNameError, setIsCategoryNameError] =
    useState<boolean>(false);
  const [alias, setAlias] = useState<string>("");
  const [isAliasError, setIsAliasError] = useState<boolean>(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [categoryImage, setCategoryImage] = useState<Image>({} as Image);
  const [chosenImage, setChosenImage] = useState<Image>({} as Image);

  useEffect(() => {
    (async () => {
      const category = await getCategory(id as string);
      setCategoryName(category.name);
      setAlias(category.alias);
      category.image && setChosenImage(category.image);
      category.images && setCategoryImage(category.image);
    })();
  }, []);

  const handleCategoryNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCategoryName(e.currentTarget.value);
    setIsCategoryNameError(e.currentTarget.value === "");

    setAlias(normalizeAlias(e.currentTarget.value));
    setIsAliasError(e.currentTarget.value === "");
  };

  const handleAliasNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAlias(e.currentTarget.value);
    setIsAliasError(e.currentTarget.value === "");
  };

  const handleEditCategory = async () => {
    if (isAliasError || isCategoryNameError) return toast({
      title: 'Błąd w formularzu',
      status: 'error',
      duration: 4000,
      isClosable: true,
    });

    await updateCategory(id as string, categoryName, alias);

    if(chosenImage.name !== categoryImage.name) {
      await removeImageFromCategory(id as string, categoryImage.name);
      await addImageToCategory(id as string, chosenImage.name);
    }

    toast({
      title: `Kategoria "${categoryName}" została edytowana pomyślnie.`,
      status: 'success',
      duration: 4000,
      isClosable: true,
    });

    return navigate("/admin/kategorie");
  };

  return (
    <Center flexDirection="column" width="100%">
      <Heading as="h1" color={tealColor} textAlign="center">
        Edytuj "{categoryName}""
      </Heading>
      <Link as={ReactRouterLink} to="/admin/kategorie">
        Wróć
      </Link>

      <Box
        margin="60px auto"
        padding="40px"
        width="800px"
        maxWidth="90%"
        boxShadow="0 2px 5px rgba(0,0,0,0.2)"
        borderRadius={6}
      >
        <FormControl isInvalid={isAliasError} mb={6}>
          <FormLabel>Nazwa kategorii</FormLabel>
          <Input
            type="text"
            value={categoryName}
            onChange={handleCategoryNameChange}
            onBlur={handleCategoryNameChange}
          />
          {!isAliasError ? (
            <FormHelperText>Podaj nazwę kategorii.</FormHelperText>
          ) : (
            <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={isAliasError} mb={6}>
          <FormLabel>Alias</FormLabel>
          <Input
            type="text"
            value={alias}
            onChange={handleAliasNameChange}
            onBlur={handleAliasNameChange}
          />
          {!isAliasError ? (
            <FormHelperText>
              Podaj alias kategorii (Jeżeli go nie będzie, zostanie dodany
              automatycznie).
            </FormHelperText>
          ) : (
            <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
          )}
        </FormControl>
        <UIImage src={getImageUrl(chosenImage)} width="150px" objectFit="cover"/>
        <Button colorScheme="blue" onClick={onOpen}>Dodaj zdjęcie</Button>
        <ChooseImageModal isOpen={isOpen} onClose={onClose} chosenImage={chosenImage} setChosenImage={setChosenImage}/>
        <Button colorScheme="teal" onClick={handleEditCategory}>
          Edytuj
        </Button>
      </Box>
    </Center>
  );
};

export default EditCategoryPage;
