import { Flex } from "@chakra-ui/react";

import { useUser } from "../../context/UserContext";
import NavbarLink from "./NavbarLink";

interface Props {
  isNavHidden: boolean;
}

const NavigationBar = ({isNavHidden}: Props) => {
  const { isLoggedIn } = useUser();

  return ( 
  <Flex
    flexDirection={["column", "column", "row"]}
    gap={[0, 0, 8]}
    position={["absolute", "absolute", "static"]}
    top={["80px", "80px", "0"]}
    textAlign={"center"}
    w={["100%", "100%", "auto"]}
    hidden={isNavHidden}
  >
    <NavbarLink text="Strona główna" path="/strona-glowna" />
    <NavbarLink text="Oferta" path="/oferta" />
    <NavbarLink text="Produkty" path="/produkty" />
    {isLoggedIn && <NavbarLink text="Panel Admina" path="/admin/kategorie" />}
  </Flex> );
}
 
export default NavigationBar;