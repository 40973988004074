import { Box } from "@chakra-ui/react";
import CategoriesSection from "./CategoriesSection";
import Header from "./Header";
import InfoCards from "./InfoCards";
import InternetPortalsSection from "./InternetPortalsSection";
import ShippingSection from "./ShippingSection";

const HomePage = () => {
  return (
    <Box as="main">
      <Header />
      <InfoCards />
      <CategoriesSection />
      <ShippingSection />
      <InternetPortalsSection />
    </Box>
  );
};

export default HomePage;
