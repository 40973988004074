import { Box, Heading } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Category, ProductWithImagesAndCategory } from "types";
import { getCategories, getCategoryProducts } from "../../utils/requests";
import ProductsList from "./ProductsList";

const CategoryProductsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState<Category>({} as Category);
  const [products, setProducts] = useState<ProductWithImagesAndCategory[]>([]);

  useEffect(() => {
    (async () => {
      const categories = await getCategories();
      const categoryFound = categories.filter(
        (category: Category) => category.alias === params.categoryAlias
      )[0];

      if (!categoryFound) navigate("/nie-znaleziono");

      const {products} = await getCategoryProducts(categoryFound.id); 

      setCategory(categoryFound);
      setProducts(products);
    })();
  }, []);

  return (
    <Box p={4}>
      <Heading as="h1" size="xl" color="gray.700" textAlign="center">
        {category.name}
      </Heading>
      <ProductsList products={products} />
    </Box>
  );
};

export default CategoryProductsPage;
