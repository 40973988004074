import {
  Flex,
  useBoolean,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect } from "react";
import Logo from "./Logo";
import GoToContactButton from "./GoToContactButton";
import HamburgerButton from "./HamburgerButton";
import NavigationBar from "./NavigationBar";

const Menu = () => {
  const [isNavHidden, setNavHidden] = useBoolean(true);
  const isLargerThan768 = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    if (isLargerThan768[0]) setNavHidden.off();
  }, [isLargerThan768]);


  return (
    <Flex
      position={"sticky"}
      top={0}
      pr={[2, 2, 6]}
      justify={"space-between"}
      alignItems={"center"}
      bgColor={"white"}
      shadow={"tealShadow"}
      zIndex={10}
    >
      <Logo height="80px" />
      <NavigationBar isNavHidden={isNavHidden} />
      <GoToContactButton />
      <HamburgerButton setNavHidden={setNavHidden.toggle}/>
    </Flex>
  );
};

export default Menu;
