import { Button, Flex, Image as UIImage, useToast } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Image } from "types";
import { deleteImage } from "../../../utils/requests";
import { getImageUrl } from "../../../utils/getImageUrl";

interface Props {
  imageToDelete: Image;
  setImages: React.Dispatch<React.SetStateAction<Image[]>>;
}

const ImageCard = ({ imageToDelete, setImages }: Props) => {
  const toast = useToast();

  const handleDeleteImage = async () => {
    const status = await deleteImage(imageToDelete.name);
    if(status !== 200) {
      return toast({
        title: 'Nie udało się usunąć obrazu.',
        description: 'Prawdopodobnie obraz uzywany jest w innym miejscu (kategorii lub produkcie).',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }

    setImages(prev => prev.filter(image => image.name !== imageToDelete.name));
    return toast({
      title: 'Obraz usunięty pomyślnie.',
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  const imageUrl = getImageUrl(imageToDelete);

  return (
    <Flex flexDirection="column" flexBasis={["90%", "90%", "45%", "30%", "24%"]}>
      <UIImage src={imageUrl} height="150px" objectFit="cover" />
      <Button colorScheme="red" borderTopRadius="0" onClick={handleDeleteImage}><DeleteIcon /></Button>
    </Flex>
  );
};

export default ImageCard;
