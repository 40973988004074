import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Text,
  Link,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import {Link as ReactRouterLink} from "react-router-dom";
import { Mail } from "types";
import { useContact } from "../../context/ContactContext";
import { tealColor } from "../../theme";
import { isRequiredCheckbox, isRequiredText } from "../../utils/formValidation";
import { sendMail } from "../../utils/requests";

interface FormValues {
  name: string;
  email: string;
  message: string;
}

const ContactForm = () => {
  const toast = useToast();
  const { message } = useContact();

  const handleSendMessage = async (values: FormValues) => {
    const mail: Mail = {
      from: values.email,
      senderName: values.name,
      text: values.message,
    }

    const status = await sendMail(mail);

    if(status === 201) {
      return toast({
          title: `Email został wysłany pomyślnie.`,
          status: 'success',
          duration: 4000,
          isClosable: true,
      })
    }

    return toast({
      title: 'Coś poszło nie tak.. Skontaktuj się z waldemar.miszczak@wp.pl',
      status: 'error',
      duration: 8000,
      isClosable: true,
    });
  }

  return (
    <Box my="20px" mx="auto" width="800px" maxWidth="90%">
      <Formik 
        initialValues={{
          name: '',
          email: '',
          message,
          privacyPolicyAccepted: false,
        }}
        
        onSubmit={handleSendMessage}
      >
        {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={!!errors.name && touched.name} mb={6}>
                <FormLabel htmlFor="name">Imię i Nazwisko</FormLabel>
                <Field 
                  as={Input}
                  type="text"
                  name="name"
                  validate={isRequiredText}
                />
                {!(!!errors.name && touched.name) ? (
                  <FormHelperText>Podaj maila swoje imię i nazwisko.</FormHelperText>
                ) : (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.email && touched.email} mb={6}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Field 
                  as={Input}
                  type="text"
                  name="email"
                  validate={isRequiredText}
                />
                {!(!!errors.email && touched.email) ? (
                  <FormHelperText>
                    Podaj maila, na którego mamy odpowiedzieć.
                  </FormHelperText>
                ) : (
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.message && touched.message} mb={6}>
                <FormLabel>Wiadomość</FormLabel>
                <Field 
                  as={Textarea}
                  name="message"
                  validate={isRequiredText}
                  height="400px"
                  resize="none"
                />
                {!(!!errors.message && touched.message) ? (
                  <FormHelperText>
                    Powiedz nam o co chciałbyś się zapytać.
                  </FormHelperText>
                ) : (
                  <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
                )}
              </FormControl>
              <Button type="submit" disabled={!!errors.privacyPolicyAccepted} colorScheme="teal">Wyślij!</Button>
              <Field 
                as={Checkbox}
                id="privacyPolicyAccepted"
                name="privacyPolicyAccepted"
                colorScheme="teal"
                validate={isRequiredCheckbox}
              >
                <Box fontSize="0.7em" textAlign="justify" mt={4}>
                  <Text>Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do preztworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest <strong>W.M.Styropian</strong>. Więcej informacji znaleźć można na stronie <Link as={ReactRouterLink} to={"/polityka-prywatnosci"} color={tealColor}>polityki prywatności</Link> serwisu</Text>
                </Box>
              </Field>
            </form>
          )}
      </Formik>
    </Box>
  );
};

export default ContactForm;
