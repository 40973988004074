import { Flex, Link } from "@chakra-ui/react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { tealColor } from "../../theme";

const AdminAsideNavigation = () => {
  return (
    <Flex flexBasis="20%" bgColor={tealColor} color="white" flexDir="column">
      <Link
        p={4}
        as={ReactRouterLink}
        to="/admin/kategorie"
        textAlign="center"
        fontSize="1.2em"
        _activeLink={{
          fontWeight: "bold",
        }}
      >
        Kategorie
      </Link>
      <Link
        p={4}
        as={ReactRouterLink}
        to="/admin/produkty"
        textAlign="center"
        fontSize="1.2em"
        _activeLink={{
          fontWeight: "bold",
        }}
      >
        Produkty
      </Link>
      <Link
        p={4}
        as={ReactRouterLink}
        to="/admin/obrazy"
        textAlign="center"
        fontSize="1.2em"
        _activeLink={{
          fontWeight: "bold",
        }}
      >
        Obrazy
      </Link>
    </Flex>
  );
};

export default AdminAsideNavigation;
