import { Box, Flex, Heading, Image, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import { tealColor } from "../../theme";

const InternetPortalsSection = () => {
  return ( 
    <Box as="section" p="20px" maxWidth="1360px" mx="auto">
      <Heading as="h2" size="xl" textAlign="center" color="gray.700">
        Portale internetowe
      </Heading>
      <Text color={tealColor} fontWeight="bold" textAlign="center">Znajdziesz nas też tutaj</Text>
      <Flex justifyContent="space-around" p={10} flexWrap="wrap">
        <LinkBox width="200px" height="200px" _hover={{
          boxShadow: `0px 2px 20px ${tealColor}`,
          }}>
          <Image src="allegro.jpg"/>
          <LinkOverlay href="https://allegro.pl/uzytkownik/waldek08081984" target="_blank"/>
        </LinkBox>
        <LinkBox width="200px" height="200px" _hover={{
          boxShadow: `0px 2px 20px ${tealColor}`,
          }}>
          <Image objectFit="contain"  height="200px" src="olx.png"/>
          <LinkOverlay href="https://www.olx.pl/d/oferty/uzytkownik/2wJsR/" target="_blank"/>
        </LinkBox>
        <LinkBox width="200px" height="200px" _hover={{
          boxShadow: `0px 2px 20px ${tealColor}`,
          }}>
          <Image src="sprzedajemy.png"/>
          <LinkOverlay href="https://sprzedajemy.pl/oferty-uzytkownika-770962" target="_blank"/>
        </LinkBox>
      </Flex>
    </Box>
   );
}
 
export default InternetPortalsSection;