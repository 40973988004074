import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link,
  Image as UIImage,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { tealColor } from "../../../theme";
import { getImageUrl } from "../../../utils/getImageUrl";
import { normalizeAlias } from "../../../utils/normalizeAlias";
import { addCategory, addImageToCategory } from "../../../utils/requests";
import ChooseImageModal from "../Product/ChooseImageModal";
import { Image } from "types";

const NewCategoryPage = () => {
  const [categoryName, setCategoryName] = useState<string>("");
  const [isCategoryNameError, setIsCategoryNameError] =
    useState<boolean>(false);
  const [alias, setAlias] = useState<string>("");
  const [isAliasError, setIsAliasError] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [chosenImage, setChosenImage] = useState<Image>({} as Image);

  const handleCategoryNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCategoryName(e.currentTarget.value);
    setIsCategoryNameError(e.currentTarget.value === "");

    setAlias(normalizeAlias(e.currentTarget.value));
    setIsAliasError(e.currentTarget.value === "");
  };

  const handleAliasNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAlias(e.currentTarget.value);
    setIsAliasError(e.currentTarget.value === "");
  };

  const handleAddCategory = async () => {
    if (isAliasError || isCategoryNameError) return toast({
      title: 'Błąd w formularzu',
      status: 'error',
      duration: 4000,
      isClosable: true,
    });

    const newCategory = await addCategory(categoryName, alias);
    
    if(chosenImage.name) {
      await addImageToCategory(newCategory.id, chosenImage.name);
    }

    toast({
      title: `Kategoria "${categoryName}" została dodana pomyślnie.`,
      status: 'success',
      duration: 4000,
      isClosable: true,
    });

    return navigate("/admin/kategorie");
  };

  return (
    <Center flexDirection="column" width="100%">
      <Heading as="h1" color={tealColor} textAlign="center">
        Nowa kategoria
      </Heading>
      <Link as={ReactRouterLink} to="/admin/kategorie">
        Wróć
      </Link>

      <Box
        margin="60px auto"
        padding="40px"
        width="800px"
        maxWidth="90%"
        boxShadow="0 2px 5px rgba(0,0,0,0.2)"
        borderRadius={6}
      >
        <FormControl isInvalid={isCategoryNameError} mb={6}>
          <FormLabel>Nazwa kategorii</FormLabel>
          <Input
            type="text"
            value={categoryName}
            onChange={handleCategoryNameChange}
            onBlur={handleCategoryNameChange}
          />
          {!isCategoryNameError ? (
            <FormHelperText>Podaj nazwę kategorii.</FormHelperText>
          ) : (
            <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={isAliasError} mb={6}>
          <FormLabel>Alias</FormLabel>
          <Input
            type="text"
            value={alias}
            onChange={handleAliasNameChange}
            onBlur={handleAliasNameChange}
          />
          {!isAliasError ? (
            <FormHelperText>
              Podaj alias kategorii (Jeżeli go nie będzie, zostanie dodany
              automatycznie).
            </FormHelperText>
          ) : (
            <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
          )}
        </FormControl>
        <UIImage src={getImageUrl(chosenImage)} width="150px" objectFit="cover"/>
        <Button colorScheme="blue" onClick={onOpen}>Dodaj zdjęcie</Button>
        <ChooseImageModal isOpen={isOpen} onClose={onClose} chosenImage={chosenImage} setChosenImage={setChosenImage}/>
        <Button colorScheme="teal" onClick={handleAddCategory}>
          Dodaj
        </Button>
      </Box>
    </Center>
  );
};

export default NewCategoryPage;
