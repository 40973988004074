import { Link } from "@chakra-ui/react";
import {NavLink} from "react-router-dom";

interface Props{
  text: string;
  path: string;
}

const NavbarLink = ({text, path}: Props) => {
  return ( <Link
    as={NavLink}
    to={path}
    fontWeight={"bold"}
    bgColor={["gray.100", "gray.100", "unset"]}
    p={[2, 2, "unset"]}
    _activeLink={{
      color: "teal.400",
    }}
  >
    {text}
  </Link> );
}
 
export default NavbarLink;