import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { Image } from "types";
import { uploadImage } from "../../../utils/requests";

interface Props {
  setImages: React.Dispatch<React.SetStateAction<Image[]>>;
}

const AddImageForm = ({ setImages }: Props) => {
  const [images, setImagesToUpload] = useState<FileList>();
  const toast = useToast();

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) return alert("Nie wybrałes pliku!");
    setImagesToUpload(e.currentTarget.files);
  };

  const handleImageUpload = async () => {
    if (!images) return alert("Nie wybrałeś pliku!");
    const formData = new FormData();

    formData.append("images", images[0]);
    const newImage = await uploadImage(formData);

    setImages((prev) => [...prev, newImage]);
    return toast({
      title: 'Obraz dodany pomyślnie',
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  };
  return (
    <Box>
      <FormControl mb={6} width="600px" maxWidth="90%" mx="auto">
        <FormLabel>Dodaj nowy obraz:</FormLabel>
        <Input type="file" onChange={handleImageChange} />
        <FormHelperText>Wybierz obraz, który chcesz wgrać.</FormHelperText>
        <Button mt={2} colorScheme="teal" onClick={handleImageUpload}>
          Dodaj zdjęcie
        </Button>
      </FormControl>
    </Box>
  );
};

export default AddImageForm;
