import { Link as ReactRouterLink } from "react-router-dom";
import { Box, Heading, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CategoryWithImage } from "types";
import CategoriesBox from "../Offer/CategoriesBox";
import { getCategories } from "../../utils/requests";
import { tealColor } from "../../theme";

const CategoriesSection = () => {
  const [categories, setCategories] = useState<CategoryWithImage[]>([]);
  
  const fetchCategories = async () => {
    const fetchedCategories = await getCategories(6);
    setCategories(fetchedCategories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Box as="section" backgroundColor={tealColor} pb={6}>
      <Heading as="h2" mb={6} size="xl" textAlign="center" color="white">
        Oferta
      </Heading>
      <CategoriesBox categories={categories} />
      <Box margin="20px auto 0 " textAlign="right" width="800px" maxWidth="90%">
        <Link
          as={ReactRouterLink}
          to="/oferta"
          color="white"
          fontSize="1.2em"
          fontWeight={"bold"}
          textDecoration="underline"
        >
          Zobacz więcej
        </Link>
      </Box>
    </Box>
  );
};

export default CategoriesSection;
