import { Flex } from "@chakra-ui/react";
import { CategoryWithImage } from "types";
import CategoryCard from "./CategoryCard";

interface Props {
  categories: CategoryWithImage[];
}

const CategoriesBox = ({ categories }: Props) => {
  return (
    <Flex
      margin="10px auto"
      flexWrap="wrap"
      flexDirection={["column", "column", "row"]}
      justifyContent="space-around"
      alignItems="center"
      gap={4}
      width="1200px"
      maxWidth="90%"
    >
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} />
      ))}
    </Flex>
  );
};

export default CategoriesBox;
