import { Flex, Image as UIImage } from "@chakra-ui/react";
import { getImageUrl } from "../../../utils/getImageUrl";
import {Image} from "types";
import { tealColor } from "../../../theme";
import { useEffect, useState } from "react";

interface Props{
  image: Image;
  chosenImage: Image;
  setChosenImage: React.Dispatch<React.SetStateAction<Image>>;
}

const ChooseImageCard = ({image, chosenImage, setChosenImage}: Props) => {
  const [isChosen, setIsChosen] = useState<boolean>(false);
  const [styles, setStyles] = useState<Object>({});
  const imageUrl = getImageUrl(image);
  const chosenStyles = {
    boxShadow: `0 2px 20px ${tealColor}`,
  };

  useEffect(() => {
    if(chosenImage.name === image.name) {
      setIsChosen(true);
      setStyles(chosenStyles);
    }
    else {
      setIsChosen(false);
      setStyles({});
    }
  }, [chosenImage])

  return ( 
    <Flex flexDirection="column" flexBasis={["90%", "90%", "45%", "30%", "24%"]}>
      <UIImage src={imageUrl} height="150px" objectFit="cover" borderRadius="10px" cursor="pointer" onClick={() => setChosenImage(image)} {...styles} />
    </Flex> );
}
 
export default ChooseImageCard;