export const normalizeAlias = (name: string): string => {
  const letters = [" ", "ą", "ć", "ę", "ł", "ń", "ó", "ś", "ź", "ż"];
  const replacement = ["-", "a", "c", "e", "l", "n", "o", "s", "z", "z"];

  let alias = name.toLocaleLowerCase();

  for (let i = 0; i < letters.length; ++i) {
    alias = alias.replaceAll(letters[i], replacement[i]);
  }

  return alias;
};
