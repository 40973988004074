import { Box, Heading } from "@chakra-ui/react";
import { tealColor } from "../../../theme";
import AddImageForm from "./AddImageForm";
import ImagesList from "./ImagesList";
import { useEffect, useState } from "react";
import { Image } from "types";
import { getImages } from "../../../utils/requests";

const AdminImagesPage = () => {
  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    (async () => {
      const fetchedImages = await getImages();
      setImages(fetchedImages);
    })();
  }, []);

  return (
    <Box flexBasis="80%">
      <Heading p={4} as="h1" color={tealColor} textAlign="center">
        Obrazy
      </Heading>
      <AddImageForm setImages={setImages} />
      <ImagesList setImages={setImages} images={images} />
    </Box>
  );
};

export default AdminImagesPage;
