import { Box, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faBox, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { tealColor } from "../../theme";

const ShippingSection = () => {
  return ( 
  <Box as="section" py="40px" px="20px" maxWidth="1360px" mx="auto">
  <Heading as="h2" size="xl" textAlign="center" color="gray.700">
    O zamówieniu
  </Heading>
    <Flex flexDir={["column", "column", "column", "row"]} >
      <Image p={2} src="dostawa.png" maxWidth="740px" width={["90%", "90%", "90%", "45%"]} flexBasis={["90%", "90%", "45%"]} mx="auto" objectFit="cover"/>
      <VStack mt="40px" alignItems="flex-start" gap={4} color={tealColor} fontWeight="bold" textAlign="center">
        <Box alignSelf="center" color="gray.700" fontSize="2em">
          <FontAwesomeIcon icon={faClock} />
        </Box>
        <Text width="100%">Po dokonaniu zamówienia i zapłaty przechodzimy do realizacji zamówienia <br /> - zwykle trwa to dwa dni robocze</Text>
        <Box alignSelf="center" color="gray.700" fontSize="2em">
          <FontAwesomeIcon icon={faBox} />
        </Box>
        <Text width="100%" >Towar gotowy do wysyłki jest przez nas zabezpieczony <br /> - wzmocniony kartonem i folią bąbelkową</Text>
        <Box alignSelf="center" color="gray.700" fontSize="2em">
          <FontAwesomeIcon icon={faMailBulk}/>
        </Box>
        <Text width="100%">Wysyłki są realizowane codziennie w godzinach popołudniowych</Text>
        <Text textAlign="center" width="100%">Zamówienia wysyłamy:</Text>
        <Flex width="100%" justifyContent="space-around">
          <Image maxWidth="150px" src="poczta-polska.jpg" alt="Poczta Polska"/>
          <Image maxWidth="150px" src="inpost.jpg" alt="InPost"/>
        </Flex>
      </VStack>
    </Flex>
  </Box> );
}
 
export default ShippingSection;