import { Box, Text } from "@chakra-ui/react";
import { faEnvelope, faLocationDot, faMoneyCheckDollar, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactRow from "./ContactRow";

const ContactTypes = () => {
  return ( 
  <Box>
    <ContactRow
      IconComponent={<FontAwesomeIcon icon={faLocationDot} />}
      title="Adres"
      text="96-100 Skierniewice, Mokra Lewa 75w"
    />
    <ContactRow IconComponent={<FontAwesomeIcon icon={faPhone}/>} title="Telefon" text="723-962-778" />
    <ContactRow
      IconComponent={<FontAwesomeIcon icon={faEnvelope}/>}
      title="Email"
      text={<Text>waldemar.miszczak@wp.pl<br/>kontakt@wmstyropian.pl</Text>}
    />
    <ContactRow
      IconComponent={<FontAwesomeIcon icon={faMoneyCheckDollar}  />}
      title="Konto bankowe"
      text="98 1140 2004 0000 3602 7935 3358"
    />
  </Box> );
}
 
export default ContactTypes;