import { createContext, useContext, useState } from "react";

interface ContactContextInterface {
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const ContactContext = createContext<ContactContextInterface | null>(
  null
);

interface Props {
  children: React.ReactNode;
}

const ContactProvider: React.FC<Props> = ({ children }) => {
  const [message, setMessage] = useState<string>("");

  return (
    <ContactContext.Provider value={{ message, setMessage }}>
      {children}
    </ContactContext.Provider>
  );
};

export const useContact = () =>
  useContext(ContactContext) as ContactContextInterface;

export default ContactProvider;
