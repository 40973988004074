import { Button } from "@chakra-ui/react";
import { useUser } from "../../context/UserContext";
import { logout } from "../../utils/requests";

const LogoutButton = () => {
  const { setIsLoggedIn } = useUser();

  const handleLogoutClick = async () => {
    const status = await logout();

    if (status === 200) setIsLoggedIn(false);
    else alert("Coś poszło nie tak...");
  };

  return ( 
    <Button colorScheme="teal" onClick={handleLogoutClick}>
      Wyloguj się
    </Button>
   );
}
 
export default LogoutButton;