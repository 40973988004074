import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Image as UIImage,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { addImageToProduct, addProduct, addProductToCategory, getCategories } from "../../../utils/requests";
import { tealColor } from "../../../theme";
import { Image } from "types";
import ChooseImageModal from "./ChooseImageModal";
import { getImageUrl } from "../../../utils/getImageUrl";

const NewProductPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [categoriesOptions, setCategoriesOptions] = useState<{
    value: string;
    name: string;
}[]>([]);
  const [categoryId, setCategoryId] = useState<string>("");

  useEffect(() => {
    (async () => {
      const fetchedCategories = await getCategories();
      const options = fetchedCategories.map((category) => ({value: category.id, name: category.name}));
      setCategoriesOptions(options);
    })();
  }, [])

  const [productName, setProductName] = useState<string>("");
  const [isProductNameError, setProductNameError] = useState<boolean>(false);

  const handleProductNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setProductName(e.currentTarget.value);
    setProductNameError(e.currentTarget.value === "");
  }

  const [description, setDescription] = useState<string>("");

  const handleDescriptionChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value);
  }

  const [price, setPrice] = useState<string>("");
  const [isPriceError, setPriceError] = useState<boolean>(false);

  const handlePriceChange = (value: string) => {
    if(value.indexOf(".") !== -1) value = value.slice(0, value.indexOf(".") + 3);
    setPrice(value);
    setPriceError((value) === "");
  }

  const handleSetCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategoryId(e.currentTarget.value);
  }
  
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [chosenImage, setChosenImage] = useState<Image>({} as Image);

  const [allegroURL, setAllegroURL] = useState<string>("");
  const handleAllegroURLChange = (e: React.FormEvent<HTMLInputElement>) => {
    setAllegroURL(e.currentTarget.value);
  }

  const [olxURL, setOlxURL] = useState<string>("");
  const handleOlxURLChange = (e: React.FormEvent<HTMLInputElement>) => {
    setOlxURL(e.currentTarget.value);
  }

  const [sprzedajemyURL, setSprzedajemyURL] = useState<string>("");
  const handleSprzedajemyURLChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSprzedajemyURL(e.currentTarget.value);
  }

  const handleAddProduct = async () => {
    if (isProductNameError || isPriceError) return toast({
      title: 'Błąd w formularzu',
      status: 'error',
      duration: 4000,
      isClosable: true,
    });

    const newProduct = await addProduct(productName, description, price, allegroURL, olxURL, sprzedajemyURL);

    if(categoryId) {
      await addProductToCategory(categoryId, newProduct.id);
    }

    if(chosenImage.name) {
      await addImageToProduct(newProduct.id, chosenImage.name);
    }

    toast({
      title: `Produkt "${productName}" został dodany pomyślnie.`,
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    return navigate("/admin/produkty");
  }

  return (
    <Center flexDirection="column" width="100%">
      <Heading as="h1" color={tealColor} textAlign="center">
        Nowy produkt
      </Heading>
      <Link as={ReactRouterLink} to="/admin/produkty">
        Wróć
      </Link>
      <Box
        margin="60px auto"
        padding="40px"
        width="800px"
        maxWidth="90%"
        boxShadow="0 2px 5px rgba(0,0,0,0.2)"
        borderRadius={6}
      >
        <FormControl isInvalid={isProductNameError} mb={6}>
          <FormLabel>Nazwa produktu</FormLabel>
          <Input
            type="text"
            value={productName}
            onChange={handleProductNameChange}
            onBlur={handleProductNameChange}
          />
          {!isProductNameError ? (
            <FormHelperText>Podaj nazwę produktu.</FormHelperText>
          ) : (
            <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mb={6}>
          <FormLabel>Krótki opis</FormLabel>
          <Textarea
            value={description}
            onChange={handleDescriptionChange}
            onBlur={handleDescriptionChange}
          />
        </FormControl>
        <FormControl isInvalid={isPriceError} mb={6}>
          <FormLabel>Cena</FormLabel>
          <InputGroup>
          <NumberInput
            precision={2}
            value={price}
            onChange={(valueString) => handlePriceChange(valueString)}
          >
            <NumberInputField />
          </NumberInput>
          <InputRightAddon children="zł" />
          </InputGroup>
          {!isPriceError ? (
            <FormHelperText>Podaj cenę produktu, grosze po kropce (.)</FormHelperText>
          ) : (
            <FormErrorMessage>Pole jest wymagane.</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mb={6}>
          <FormLabel>URL Allegro</FormLabel>
          <Input
            type="text"
            value={allegroURL}
            onChange={handleAllegroURLChange}
            onBlur={handleAllegroURLChange}
          />
          <FormHelperText>Dodaj adres strony produktu na Allegro.</FormHelperText>
        </FormControl>
        <FormControl mb={6}>
          <FormLabel>URL OLX</FormLabel>
          <Input
            type="text"
            value={olxURL}
            onChange={handleOlxURLChange}
            onBlur={handleOlxURLChange}
          />
          <FormHelperText>Dodaj adres strony produktu na OLX.</FormHelperText>
        </FormControl>
        <FormControl mb={6}>
          <FormLabel>URL Sprzedajemy.pl</FormLabel>
          <Input
            type="text"
            value={sprzedajemyURL}
            onChange={handleSprzedajemyURLChange}
            onBlur={handleSprzedajemyURLChange}
          />
          <FormHelperText>Dodaj adres strony produktu na Sprzedajemy.pl.</FormHelperText>
        </FormControl>
        <FormControl mb={6}>
          <FormLabel>Kategoria</FormLabel>
          <Select onChange={handleSetCategory}>
            <option value=""></option>
            {categoriesOptions.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
          </Select>
        </FormControl>
        <UIImage src={getImageUrl(chosenImage)} width="150px" objectFit="cover"/>
        <Button colorScheme="blue" onClick={onOpen}>Dodaj zdjęcie</Button>
        <ChooseImageModal isOpen={isOpen} onClose={onClose} chosenImage={chosenImage} setChosenImage={setChosenImage}/>
        <Divider my={2}/>
        <Button colorScheme="teal" onClick={handleAddProduct}>
          Dodaj
        </Button>
      </Box>
    </Center>
  );
};

export default NewProductPage;
