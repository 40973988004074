import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { tealColor } from "../../theme";
import { CategoryWithImage } from "types";
import { getCategoryImageUrl } from "../../utils/getImageUrl";

interface Props {
  category: CategoryWithImage;
}

const CategoryCard = ({ category }: Props) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/${category.alias}`);
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width={["90%", "90%", "300px"]}
      maxWidth="90%"
      flexBasis={["90%", "90%", "40%", "30%"]}
      color="white"
      backgroundColor={tealColor}
      fontWeight="bold"
      boxShadow="0 2px 10px #2D3748"
      borderRadius="10px"
      cursor="pointer"
      transitionDuration=".3s"
      _hover={{
        backgroundColor: "white",
        color: tealColor,
      }}
      onClick={handleCardClick}
    >
      <Image height="140px" flexBasis="40%" maxWidth="140px" objectFit="cover" borderLeftRadius="10px" src={getCategoryImageUrl(category)} />
      <Text flexBasis="60%" textAlign="center" px={2} fontSize="1.2em">{category.name}</Text>
    </Flex>
  );
};

export default CategoryCard;
