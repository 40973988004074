import { Box, Heading, ListItem, Text, UnorderedList, Link } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { tealColor } from "../../theme";

const PrivacyPolicyPage = () => {
  return ( 
  <Box maxWidth="1280px" mx="auto" textAlign="justify">
    <Heading as="h1" my={4} size="xl" color="gray.700" textAlign="center">Polityka Prywatności</Heading>
    <Text>Poniższa Polityka Prywatności określa <strong>zasady zapisywania i uzyskiwania dostępu do danych na Urządzeniach Użytkowników</strong> korzystających z Serwisu do celów świadczenia usług drogą elektroniczną przez Administratora oraz <strong>zasady gromadzenia i przetwarzania danych osobowych Użytkowników</strong>, które zostały podane przez nich osobiście i dobrowolnie za pośrednictwem narzędzi dostępnych w Serwisie.</Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§1 Definicje</Heading>
    <Text>
      <UnorderedList>
        <ListItem><strong>Serwis</strong> - serwis internetowy "wmstyropian.pl" działający pod adresem https://wmstyropian.pl</ListItem>

        <ListItem><strong>Serwis zewnętrzny</strong> - serwisy internetowe partnerów, usługodawców lub usługobiorców współpracujących z Administratorem</ListItem>

        <ListItem><strong>Administrator Serwisu / Danych</strong> -  Administratorem Serwisu oraz Administratorem Danych (dalej Administrator) jest firma "W. M. Styropian", prowadząca działalność pod adresem: Mokra lewa 75w, 96-100 Skierniewice, świadcząca usługi drogą elektroniczną za pośrednictwem Serwisu.</ListItem>

        <ListItem><strong>Użytkownik</strong> - osoba fizyczna, dla której Administrator świadczy usługi drogą elektroniczną za pośrednictwem Serwisu.</ListItem>

        <ListItem><strong>Urządzenie</strong> - elektroniczne urządzenie wraz z oprogramowaniem, za pośrednictwem którego Użytkownik uzyskuje dostęp do Serwisu</ListItem>

        <ListItem><strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w formie plików zamieszczanych na Urządzeniu Użytkownika</ListItem>

        <ListItem><strong>RODO</strong> - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)</ListItem>

        <ListItem><strong>Dane osobowe</strong> - oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej („osobie, której dane dotyczą”); możliwa do zidentyfikowania osoba fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej</ListItem>

        <ListItem><strong>Przetwarzanie</strong> - oznacza operację lub zestaw operacji wykonywanych na danych osobowych lub zestawach danych osobowych w sposób zautomatyzowany lub niezautomatyzowany, taką jak zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub modyfikowanie, pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie;</ListItem>

        <ListItem><strong>Ograniczenie przetwarzania</strong> - oznacza oznaczenie przechowywanych danych osobowych w celu ograniczenia ich przyszłego przetwarzania</ListItem>

        <ListItem><strong>Profilowanie</strong> - oznacza dowolną formę zautomatyzowanego przetwarzania danych osobowych, które polega na wykorzystaniu danych osobowych do oceny niektórych czynników osobowych osoby fizycznej, w szczególności do analizy lub prognozy aspektów dotyczących efektów pracy tej osoby fizycznej, jej sytuacji ekonomicznej, zdrowia, osobistych preferencji, zainteresowań, wiarygodności, zachowania, lokalizacji lub przemieszczania się</ListItem>

        <ListItem><strong>Zgoda</strong> - zgoda osoby, której dane dotyczą oznacza dobrowolne, konkretne, świadome i jednoznaczne okazanie woli, którym osoba, której dane dotyczą, w formie oświadczenia lub wyraźnego działania potwierdzającego, przyzwala na przetwarzanie dotyczących jej danych osobowych</ListItem>

        <ListItem><strong>Naruszenie ochrony danych osobowych</strong> - oznacza naruszenie bezpieczeństwa prowadzące do przypadkowego lub niezgodnego z prawem zniszczenia, utracenia, zmodyfikowania, nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych osobowych przesyłanych, przechowywanych lub w inny sposób przetwarzanych</ListItem>

        <ListItem><strong>Pseudonimizacja</strong> - oznacza przetworzenie danych osobowych w taki sposób, by nie można ich było już przypisać konkretnej osobie, której dane dotyczą, bez użycia dodatkowych informacji, pod warunkiem że takie dodatkowe informacje są przechowywane osobno i są objęte środkami technicznymi i organizacyjnymi uniemożliwiającymi ich przypisanie zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej</ListItem>

        <ListItem><strong>Anonimizacja</strong> - Anonimizacja danych to nieodwracalny proces operacji na danych, który niszczy / nadpisuje "dane osobowe" uniemożliwiając identyfikację, lub powiązanie danego rekordu z konkretnym użytkownikiem lub osobą fizyczną.</ListItem>
      </UnorderedList>
    </Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§2 Inspektor Ochrony Danych</Heading>
    <Text>Na podstawie Art. 37 RODO, Administrator nie powołał Inspektora Ochrony Danych. W sprawach dotyczących przetwarzania danych, w tym danych osobowych, należy kontaktować się bezpośrednio z Administratorem.</Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§3 Rodzaje Plików Cookies</Heading>
    <Text>
      <UnorderedList>
      <ListItem><strong>Cookies wewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez system teleinformatyczny Serwisu</ListItem>

      <ListItem><strong>Cookies zewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez systemy teleinformatyczne Serwisów zewnętrznych. Skrypty Serwisów zewnętrznych, które mogą umieszczać pliki Cookies na Urządzeniach Użytkownika zostały świadomie umieszczone w Serwisie poprzez skrypty i usługi udostępnione i zainstalowane w Serwisie</ListItem>

      <ListItem><strong>Cookies sesyjne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis podczas jednej sesji danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika.</ListItem>

      <ListItem><strong>Cookies trwałe</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis do momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia chyba że konfiguracja Urządzenia Użytkownika jest ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji Urządzenia.</ListItem>
      </UnorderedList>
    </Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§4 Bezpieczeństwo składowania danych</Heading>
    <Text>
      <UnorderedList>
      <ListItem><strong>Mechanizmy składowania i odczytu plików Cookie</strong> - Mechanizmy składowania, odczytu i wymiany danych pomiędzy Plikami Cookies zapisywanymi na Urządzeniu Użytkownika a Serwisem są realizowane poprzez wbudowane mechanizmy przeglądarek internetowych i nie pozwalają na pobieranie innych danych z Urządzenia Użytkownika lub danych innych witryn internetowych, które odwiedzał Użytkownik, w tym danych osobowych ani informacji poufnych. Przeniesienie na Urządzenie Użytkownika wirusów, koni trojańskich oraz innych robaków jest także praktycznie niemożliwe.</ListItem>

      <ListItem><strong>Cookie wewnętrzne</strong> - zastosowane przez Administratora pliki Cookie są bezpieczne dla Urządzeń Użytkowników i nie zawierają skryptów, treści lub informacji mogących zagrażać bezpieczeństwu danych osobowych lub bezpieczeństwu Urządzenia z którego korzysta Użytkownik.</ListItem>

      <ListItem><strong>Cookie zewnętrzne</strong> - Administrator dokonuje wszelkich możliwych działań w celu weryfikacji i doboru partnerów serwisu w kontekście bezpieczeństwa Użytkowników. Administrator do współpracy dobiera znanych, dużych partnerów o globalnym zaufaniu społecznym. Nie posiada on jednak pełnej kontroli nad zawartością plików Cookie pochodzących od zewnętrznych partnerów. Za bezpieczeństwo plików Cookie, ich zawartość oraz zgodne z licencją wykorzystanie przez zainstalowane w serwisie Skrypty, pochodzących z Serwisów zewnętrznych, Administrator nie ponosi odpowiedzialności na tyle na ile pozwala na to prawo. Lista partnerów zamieszczona jest w dalszej części Polityki Prywatności.</ListItem>

      <ListItem><strong>Kontrola plików Cookie</strong><br/>

        Użytkownik może w dowolnym momencie, samodzielnie zmienić ustawienia dotyczące zapisywania, usuwania oraz dostępu do danych zapisanych plików Cookies przez każdą witrynę internetową.<br/>

        Informacje o sposobie wyłączenia plików Cookie w najpopularniejszych przeglądarkach komputerowych dostępne są na stronie: jak wyłączyć cookie lub u jednego ze wskazanych dostawców:<br/>

        <UnorderedList listStyleType="circle"> 
          <ListItem>Zarządzanie plikami cookies w przeglądarce <Link href={"https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&#038;hl=pl"} target="_blank" isExternal={true} color={tealColor}>Chrome</Link></ListItem>
          <ListItem>Zarządzanie plikami cookies w przeglądarce <Link href={"https://help.opera.com/pl/latest/web-preferences/"} target="_blank" isExternal={true} color={tealColor}>Opera</Link></ListItem>
          <ListItem>Zarządzanie plikami cookies w przeglądarce <Link href={"https://support.mozilla.org/pl/kb/blokowanie-ciasteczek"} target="_blank" isExternal={true} color={tealColor}>FireFox</Link></ListItem>
          <ListItem>Zarządzanie plikami cookies w przeglądarce <Link href={"https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"} target="_blank" isExternal={true} color={tealColor}>Edge</Link></ListItem>
          <ListItem>Zarządzanie plikami cookies w przeglądarce <Link href={"https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"} target="_blank" isExternal={true} color={tealColor}>Safari</Link></ListItem>
          <ListItem>Zarządzanie plikami cookies w przeglądarce <Link href={"https://windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11"} target="_blank" isExternal={true} color={tealColor}>Internet Explorer 11</Link></ListItem>
        </UnorderedList>
        Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej pory pliki Cookie korzystając z narzędzi Urządzenia Użytkownika, za pośrednictwem którego Użytkownik korzysta z usług Serwisu.</ListItem>

        <ListItem><strong>Zagrożenia po stronie Użytkownika</strong> - Administrator stosuje wszelkie możliwe środki techniczne w celu zapewnienia bezpieczeństwa danych umieszczanych w plikach Cookie. Należy jednak zwrócić uwagę, że zapewnienie bezpieczeństwa tych danych zależy od obu stron w tym działalności Użytkownika. Administrator nie bierze odpowiedzialności za przechwycenie tych danych, podszycie się pod sesję Użytkownika lub ich usunięcie, na skutek świadomej lub nieświadomej działalność Użytkownika, wirusów, koni trojańskich i innego oprogramowania szpiegującego, którymi może jest lub było zainfekowane Urządzenie Użytkownika. Użytkownicy w celu zabezpieczenia się przed tymi zagrożeniami powinni stosować się do zasad bezpiecznego korzystania z sieci internet.</ListItem>

        <ListItem><strong>Przechowywanie danych osobowych</strong> - Administrator zapewnia, że dokonuje wszelkich starań, by przetwarzane dane osobowe wprowadzone dobrowolnie przez Użytkowników były bezpieczne, dostęp do nich był ograniczony i realizowany zgodnie z ich przeznaczeniem i celami przetwarzania. Administrator zapewnia także, że dokonuje wszelkich starań w celu zabezpieczenia posiadanych danych przed ich utratą, poprzez stosowanie odpowiednich zabezpieczeń fizycznych jak i organizacyjnych.</ListItem>

        <ListItem><strong>Przechowywanie haseł</strong> - Administrator oświadcza, że hasła przechowywane są w zaszyfrowanej postaci, używając najnowszych standardów i wytycznych w tym zakresie. Deszyfracja podawanych w Serwisie haseł dostępu do konta jest praktycznie niemożliwa.</ListItem>
      </UnorderedList>
    </Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§5 Cele do których wykorzystywane są pliki Cookie</Heading>
    <UnorderedList>
      <ListItem>Usprawnienie i ułatwienie dostępu do Serwisu</ListItem>
      <ListItem>Personalizacja Serwisu dla Użytkowników</ListItem>
      <ListItem>Umożliwienie Logowania do serwisu</ListItem>
    </UnorderedList>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§6 Cele przetwarzania danych osobowych</Heading>
    <Text>
    Dane osobowe dobrowolnie podane przez Użytkowników są przetwarzane w jednym z następujących celów:
    <UnorderedList>
      <ListItem>Usługi rejestracji i utrzymania konta Użytkownika w Serwisie i funkcjonalności z nim związanych</ListItem>
      <ListItem>Komunikacji Administratora z Użytkownikami w sprawach związanych z Serwisem oraz ochrony danych</ListItem>
      <ListItem>Zapewnienia prawnie uzasadnionego interesu Administratora</ListItem>
    </UnorderedList>
    Dane o Użytkownikach gromadzone anonimowo i automatycznie są przetwarzane w celu zapewnienia prawnie uzasadnionego interesu Administratora.
    </Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§7 Pliki Cookies Serwisów zewnętrznych</Heading>
    <Text>
      Administrator w Serwisie nie wykorzystuje skryptów, czy komponentów webowych stron trzecich.
    </Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§8 Rodzaje gromadzonych danych</Heading>
    <Text>
      Serwis gromadzi dane o Użytkownikach. Cześć danych jest gromadzona automatycznie i anonimowo, a część danych to dane osobowe podane dobrowolnie przez Użytkowników w trakcie zapisywania się do poszczególnych usług oferowanych przez Serwis. <br/>
      <strong>Anonimowe dane gromadzone automatycznie:</strong>
      <UnorderedList>
        <ListItem>Typ przeglądarki</ListItem>
      </UnorderedList>
      <strong>Dane gromadzone podczas korzystania z formularza kontaktowego:</strong>
      <UnorderedList>
        <ListItem>Imię / nazwisko / pseudonim</ListItem>
        <ListItem>Adres e-mail</ListItem>
      </UnorderedList>  
    </Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§9 Dostęp do danych osobowych przez podmioty trzecie</Heading>
    <Text>Co do zasady jedynym odbiorcą danych osobowych podawanych przez Użytkowników jest Administrator. Dane gromadzone w ramach świadczonych usług nie są przekazywane ani odsprzedawane podmiotom trzecim.</Text>
    <Text fontWeight="bold">Powierzenie przetwarzania danych osobowych - Usługi Hostingu, VPS lub Serwerów Dedykowanych</Text>
    <Text>Administrator w celu prowadzenia serwisu korzysta z usług zewnętrznego dostawcy hostingu, VPS lub Serwerów Dedykowanych - ADMIN.NET.PL poprzez portal https://mydevil.net. Wszelkie dane gromadzone i przetwarzane w serwisie są przechowywane i przetwarzane w infrastrukturze usługodawcy zlokalizowanej w Polsce. Istnieje możliwość dostępu do danych wskutek prac serwisowych realizowanych przez personel usługodawcy. Dostęp do tych danych reguluje umowa zawarta pomiędzy Administratorem a Usługodawcą.</Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§10 Sposób przetwarzania danych osobowych</Heading>
    <Text fontWeight="bold">Dane osobowe podane dobrowolnie przez Użytkowników:</Text>
    <UnorderedList>
      <ListItem>Dane osobowe nie będą przekazywane poza Unię Europejską, chyba że zostały opublikowane na skutek indywidualnego działania Użytkownika (np. wprowadzenie komentarza lub wpisu), co sprawi, że dane będą dostępne dla każdej osoby odwiedzającej serwis.</ListItem>
      <ListItem>Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji (profilowania).</ListItem>
      <ListItem>Dane osobowe nie będą odsprzedawane podmiotom trzecim.</ListItem>
    </UnorderedList>
    <Text fontWeight="bold">Dane anonimowe (bez danych osobowych) gromadzone automatycznie:</Text>
    <UnorderedList>
      <ListItem>Dane anonimiwe (bez danych osobowych) nie będą przekazywane poza Unię Europejską.</ListItem>
      <ListItem>Dane anonimiwe (bez danych osobowych) nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji (profilowania).</ListItem>
      <ListItem>Dane anonimiwe (bez danych osobowych) nie będą odsprzedawane podmiotom trzecim.</ListItem>
    </UnorderedList>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§11 Podstawy prawne przetwarzania danych osobowych</Heading>
    <Text>Serwis gromadzi i przetwarza dane Użytkowników na podstawie:</Text>
    <UnorderedList>
      <ListItem>
        Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
        <UnorderedList listStyleType="circle">
          <ListItem>art. 6 ust. 1 lit. a</ListItem>
          <ListItem>art. 6 ust. 1 lit. b</ListItem>
          <ListItem>art. 6 ust. 1 lit. f</ListItem>
        </UnorderedList>
      </ListItem>
      <ListItem>Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U. 2018 poz. 1000)</ListItem>
      <ListItem>Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 nr 171 poz. 1800)</ListItem>
      <ListItem>Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz. U. 1994 Nr 24 poz. 83)</ListItem>
    </UnorderedList>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§12 Okres przetwarzania danych osobowych</Heading> 
    <Text fontWeight="bold">Dane osobowe podane dobrowolnie przez Użytkowników:</Text>
    <Text>Co do zasady wskazane dane osobowe są przechowywane wyłącznie na skrzynce pocztowej Administratora po wysłaniu ich poprzez formularz kontaktowy. Dane są wykorzystywane podczas komunikacji mailowej i mogą zostać usunięte w dowolnym momencie na żądanie Uzytkownika </Text>
    <Text fontWeight="bold">Dane anonimowe (bez danych osobowych) gromadzone automatycznie:</Text>
    <Text>Anonimowe dane, niestanowiące danych osobowych, przechowywane są przez Administratora w logach aplikacji na potrzeby prowadzenia statystyk na czas nieoznaczony.</Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§13 Prawa Użytkowników związane z przetwarzaniem danych osobowych</Heading> 
    <Text>
      Serwis gromadzi i przetwarza dane Użytkowników na podstawie:
      <UnorderedList>
        <ListItem>Prawo dostępu do danych osobowych<br/>Użytkownikom przysługuje prawo uzyskania dostępu do swoich danych osobowych, realizowane na żądanie złożone do Administratora</ListItem>
        <ListItem>Prawo do sprostowania danych osobowych<br/>Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego sprostowania danych osobowych, które są nieprawidłowe lub / oraz uzupełnienia niekompletnych danych osobowych, realizowane na żądanie złożone do Administratora</ListItem>
        <ListItem>Prawo do usunięcia danych osobowych<br/>Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego usunięcia danych osobowych, realizowane na żądanie złożone do Administratora W przypadku kont użytkowników, usunięcie danych polega na anonimizacji danych umożliwiających identyfikację Użytkownika. Administrator zastrzega sobie prawo wstrzymania realizacji żądania usunięcia danych w celu ochrony prawnie uzasadnionego interesu Administratora (np. dane zostały pozyskane wskutek prowadzonej korespondencji).
          </ListItem>
        <ListItem>
        Prawo do ograniczenia przetwarzania danych osobowych<br/>Użytkownikom przysługuje prawo ograniczenia przetwarzania danych osobowych w przypadkach wskazanych w art. 18 RODO, m.in. kwestionowania prawidłowość danych osobowych, realizowane na żądanie złożone do Administratora</ListItem>
        <ListItem>Prawo do przenoszenia danych osobowych<br/>Użytkownikom przysługuje prawo uzyskania od Administratora, danych osobowych dotyczących Użytkownika w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego, realizowane na żądanie złożone do Administratora</ListItem>
        <ListItem>Prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych<br/>Użytkownikom przysługuje prawo wniesienia sprzeciwu wobec przetwarzania jego danych osobowych w przypadkach określonych w art. 21 RODO, realizowane na żądanie złożone do Administratora</ListItem>
        <ListItem>Prawo wniesienia skargi<br/>Użytkownikom przysługuje prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych.</ListItem>
      </UnorderedList>
    </Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§14 Kontakt do Administratora</Heading> 
    <Text>
    Z Administratorem można skontaktować się w jeden z poniższych sposobów
      <UnorderedList>
        <ListItem><strong>Adres pocztowy</strong> - W. M. Styropian, Mokra lewa 75w, 96-100 Skierniewice</ListItem>
        <ListItem><strong>Adres poczty elektronicznej</strong> - waldemar.miszczak@wp.pl</ListItem>
        <ListItem><strong>Formularz kontaktowy</strong> - dostępny pod adresem: <Link href="https://wmstyropian.pl/kontakt" color={tealColor}>https://wmstyropian.pl/kontakt</Link></ListItem>
      </UnorderedList>
    </Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§15 Wymagania Serwisu</Heading> 
    <UnorderedList>
      <ListItem>Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu Użytkownika może spowodować nieprawidłowe działanie niektórych funkcji Serwisu.</ListItem>
      <ListItem>Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo działające funkcje Serwisu w przypadku gdy Użytkownik ograniczy w jakikolwiek sposób możliwość zapisywania i odczytu plików Cookie.</ListItem>
    </UnorderedList>
    
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§16 Linki zewnętrzne</Heading> 
    <Text>Na stronie znajdują się linki zewnętrzne do portali Allegro, OLX oraz Sprzedajemy.pl. Są to odnośniki do aukcji prowadzonych przez Administratora na portalach zewnętrznych i odpowiada za nie Administrator. Poza tym żaden Użytkownik nie ma mozliwości wstawiania odnosników do wutrzyn zewnętrznych.</Text>
    <Heading as="h2" my={4} size="xl" color={tealColor} textAlign="center">§17 Zmiany w Polityce Prywatności</Heading> 
    <UnorderedList>
      <ListItem>Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Prywatności bez konieczności informowania o tym Użytkowników w zakresie stosowania i wykorzystywania danych anonimowych lub stosowania plików Cookie.</ListItem>
      <ListItem>Wprowadzone zmiany w Polityce Prywatności będą publikowane na tej podstronie Serwisu.</ListItem>
      <ListItem>Wprowadzone zmiany wchodzą w życie z chwilą ich publikacji.</ListItem>
    </UnorderedList>
  </Box> );
}
 
export default PrivacyPolicyPage;