import { Box, Divider, Heading, Link, Text } from "@chakra-ui/react";
import { useUser } from "../../context/UserContext";
import {Link as ReactRouterLink} from "react-router-dom";
import GoToLoginPageLink from "./GoToLoginPageLink";
import LogoutButton from "./LogoutButton";

const AboutBox = () => {
  const { isLoggedIn } = useUser();
  
  return ( <Box ml={[0, 20]} mt={[4, 0]} color="white" maxWidth="280px">
  <Heading as="h5" size="md" mb={1}>
    O firmie
  </Heading>
  <Text>
    W.M. Styropian to firma zajmująca się wyrobem produktów
    styropianowych.
  </Text>
  <Link
    mt={2}
    as={ReactRouterLink}
    to="/polityka-prywatnosci"
    textDecoration="underline"
  >
    Polityka prywatności
  </Link>
  <Divider my={2}/>
  {isLoggedIn ? <LogoutButton /> : <GoToLoginPageLink />}
</Box> );
}
 
export default AboutBox;