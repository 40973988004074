import { Link } from "@chakra-ui/react";
import {Link as ReactRouterLink} from "react-router-dom";

const GoToLoginPageLink = () => {
  return ( <Link
    mt={2}
    as={ReactRouterLink}
    to="/login"
    textDecoration="underline"
  >
    Strona logowania
  </Link> );
}
 
export default GoToLoginPageLink;