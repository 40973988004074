import { Box, Heading, Text } from "@chakra-ui/react";
import { tealColor } from "../../theme";
import ContactForm from "./ContactForm";

const ContactPage = () => {
  return (
    <Box>
      <Heading
        as="h1"
        size="xl"
        my={4}
        color="gray.700"
        fontWeight="bold"
        textAlign="center"
      >
        Kontakt
      </Heading>
      <Text
        mx="auto"
        width="800px"
        maxWidth="90%"
        fontWeight="bold"
        color={tealColor}
      >
        Napisz do nas z pytaniem! Postaramy się odpowiedzieć jak najszybciej!
      </Text>
      <ContactForm />
    </Box>
  );
};

export default ContactPage;
