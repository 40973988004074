import { Box, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ProductWithImagesAndCategory } from "types";
import { getProducts } from "../../utils/requests";
import ProductsList from "../CategoryProducts/ProductsList";
import SearchBox from "./SearchBox";

const SearchProductPage = () => {
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [products, setProducts] = useState<ProductWithImagesAndCategory[]>([]);

  const handleSearch = async () => {
    const fetchedProducts = await getProducts(searchPhrase);
    setProducts(fetchedProducts);
  }

  useEffect(() => {
    handleSearch();
  }, []);

  return ( 
  <Box>
    <Heading as="h1" my={4} color="gray.700" textAlign="center">
      Wyszukaj produkty
    </Heading>
    <SearchBox searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase} handleSearch={handleSearch} />
    <ProductsList products={products}/>
  </Box> );
}
 
export default SearchProductPage;