import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

interface Props {
  children: React.ReactNode;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  const { isLoggedIn } = useUser();

  if (!isLoggedIn) return <Navigate to="/login" />;

  return <>{children}</>;
};

export default RequireAuth;
