import { Flex } from "@chakra-ui/react";
import { Image } from "types";
import ImageCard from "./ImageCard";

interface Props {
  images: Image[];
  setImages: React.Dispatch<React.SetStateAction<Image[]>>;
}

const ImagesList = ({ images, setImages }: Props) => {
  return (
    <Flex flexWrap="wrap" gap={5} p={4} justifyContent="space-around">
      {images.map((image) => (
        <ImageCard key={image.name} imageToDelete={image} setImages={setImages}/>
      ))}
    </Flex>
  );
};

export default ImagesList;
