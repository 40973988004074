import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const GoToContactButton = () => {
  const navigate = useNavigate();
  
  const handleOnContactClick = () => {
    navigate("/kontakt");
  };
  
  return ( 
  <Button colorScheme={"teal"} size="lg" onClick={handleOnContactClick}>
  Kontakt
</Button> );
}
 
export default GoToContactButton;