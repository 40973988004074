import { extendTheme } from "@chakra-ui/react";

export const tealColor = "#38B2AC";
export const teaColorRGB = { r: 56, g: 178, b: 172 };

export const theme = extendTheme({
  shadows: {
    tealShadow: `0 2px 5px ${tealColor}`,
  },
  borders: {
    "1px": `1px solid ${tealColor}`,
  },
});
