import { Box, Heading } from "@chakra-ui/react";
import { tealColor } from "../../theme";

const NotFoundPage = () => {
  return (
    <Box>
      <Heading
        as="h1"
        my="20vh"
        size="xl"
        color={tealColor}
        fontWeight="bold"
        textAlign="center"
      >
        Uuuups.. Podana strona nie została odnaleziona.
      </Heading>
    </Box>
  );
};

export default NotFoundPage;
