import {Image} from '@chakra-ui/react';

interface Props {
  height?: string;
  width?: string;
}

const Logo = ({height="auto", width="auto"}: Props) => {
  return ( <Image src="logo.jpg" height={height} width={width} /> );
}
 
export default Logo;