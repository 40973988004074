import { HamburgerIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

interface Props {
  setNavHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const HamburgerButton = ({setNavHidden}: Props) => {
  return ( <Button
    display={["block", "block", "none"]}
    onClick={() => setNavHidden((prev) => !prev)}
    bgColor={"white"}
    border="2px solid"
    borderColor="teal.400"
  >
    <HamburgerIcon color={"teal.400"} />
  </Button> );
}
 
export default HamburgerButton;