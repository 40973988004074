import { Flex, Heading } from "@chakra-ui/react";
import { ProductWithImagesAndCategory } from "types";
import ProductCard from "./ProductCard";

interface Props {
  products: ProductWithImagesAndCategory[];
}

const ProductsList = ({ products }: Props) => {
  if (!products || products.length === 0)
    return (
      <Heading as="h2" size="l" fontWeight="bold" textAlign="center">
        Nie znaleziono produktów.
      </Heading>
    );

  return (
    <Flex
      padding={4}
      gap={12}
      flexDirection={["column", "row"]}
      flexWrap="wrap"
      justifyContent="space-around"
    >
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </Flex>
  );
};

export default ProductsList;
