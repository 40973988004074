import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalBody, useDisclosure, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getImages } from "../../../utils/requests";
import {Image} from 'types';
import ChooseImageCard from "./ChooseImageCard";

interface Props{
  isOpen: boolean;
  onClose: () => void;
  chosenImage: Image;
  setChosenImage: React.Dispatch<React.SetStateAction<Image>>;
}

const ChooseImageModal = ({isOpen, onClose, chosenImage, setChosenImage}: Props) => {
  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    (async () => {
      const fetchedImages = await getImages();
      setImages(fetchedImages);
    })();
  }, []);

  return ( 
  <Modal onClose={onClose} isOpen={isOpen} size="full">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Wybierz obraz</ModalHeader>
      <ModalCloseButton onClick={onClose}/>
      <ModalBody>
        <Flex flexWrap="wrap" gap={5} p={4} justifyContent="space-around">
          {images.map((image) => (
            <ChooseImageCard key={image.name} image={image} chosenImage={chosenImage} setChosenImage={setChosenImage}/>
          ))}
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>  
  );
}
 
export default ChooseImageModal;