import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { teaColorRGB, tealColor } from "../../theme";

const Header = () => {
  const navigate = useNavigate();

  const handleOfferClick = () => {
    navigate("/oferta");
  };

  return (
    <Box
      as="header"
      position="relative"
      height="calc(100vh - 80px)"
      bgColor="gray.700"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top="-20px"
        right="-10%"
        width="90vh"
        height="90vh"
        zIndex={1}
        bgColor={tealColor}
        borderRadius="50%"
      >
        <Box 
          position="absolute" 
          top="5%" 
          left="5%" 
          right="unset" 
          bottom="unset" 
          width="80vh" 
          height="80vh" 
          borderRadius="50%"
          bgImage="url(motylki.jpg)"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="-250px"
          >
        </Box>
        <Box position="absolute" top="30%" left="-15%" height="5vh" bgColor={tealColor} width="200px" borderRadius="50px">

        </Box>
        <Box position="absolute" top="30%" left="calc(-15% - 100px)" height="5vh" width="60px" bgColor={tealColor} borderRadius="50px">

        </Box>
        <Box position="absolute" top="50%" left="-80px" height="5vh" bgColor={tealColor} width="100px" borderRadius="50px">

        </Box>
      </Box>

      <Box position="relative" width="100%" height="100%" zIndex={2} bgColor={["rgba(0,0,0,0.6)", "rgba(0,0,0,0.6)", "rgba(0,0,0,0.6)","rgba(0,0,0,0.6)", "unset"]}>
      <Box
        position="absolute"
        top={["30%", "30%", "20%"]}
        left={["50%", "50%", "10%"]}
        transform={["translateX(-50%)", "translateX(-50%)", "unset"]}
        zIndex={3}
        color="white"
      >
        <Box w={["unset", "unset", "404px"]}>
          <Heading
            as="h2"
            display="flex"
            justifyContent="space-between"
            size="md"
            letterSpacing={4}
            textTransform="uppercase"
          >
            <Text>Waldemar</Text>
            <Text>Miszczak</Text>
          </Heading>
          <Heading
            as="h1"
            size="4xl"
            textTransform="uppercase"
            pb={2}
            borderBottom="6px solid"
            borderColor={tealColor}
          >
            Styropian
          </Heading>
        </Box>
        <Heading
          as="h4"
          size="md"
          mt={4}
          maxW="600px"
          textAlign={"justify"}
          fontWeight={"normal"}
        >
          Produkujemy wyroby styropianowe: kształtki, opakowania, pudełka,
          ozdoby i wiele więcej!
        </Heading>
      </Box>
      <Button
        position="absolute"
        bottom="10%"
        left={["50%", "50%", "10%"]}
        transform={["translateX(-50%)", "translateX(-50%)", "unset"]}
        height="120px"
        width="300px"
        colorScheme="orange"
        variant="solid"
        p={10}
        fontSize={["2.6em"]}
        borderRadius="50px"
        zIndex={3}
        onClick={handleOfferClick}
      >
        Oferta
      </Button>
      </Box>
    </Box>
  );
};

export default Header;
