import { ChakraProvider } from "@chakra-ui/react";
import Menu from "./common/Menu/Menu";
import Router from "./common/Router";
import Footer from "./common/Footer/Footer";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import ContactProvider from "./context/ContactContext";
import UserProvider from "./context/UserContext";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <UserProvider>
          <ContactProvider>
            <Menu />
            <Router />
            <Footer />
          </ContactProvider>
        </UserProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
